import React from 'react';

import { ErrorPage } from '../../components/shared/ErrorPage';

const InternalServerError = () => (
  <ErrorPage
    seoTitle="500: Internal server error"
    title="Něco se nám rozbilo"
    description="Zkuste, prosím, výpočet Osobního skóre za chvíli."
  />
);

export default InternalServerError;
